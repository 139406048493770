/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "@jatinkrmalik",
  title: "Hello! I'm Jatin",
  subTitle: emoji(
    "A Principal Software Engineer interested in designing and crafting efficient modern software, and learning new tools and technologies on the way!"
  ),
  resumeLink: "", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  twitter: "https://twitter.com/jatinkrmalik",
  medium: "https://medium.com/@jatinkrmalik",
  github: "https://github.com/jatinkrmalik",
  linkedin: "https://www.linkedin.com/in/jatinkrmalik/",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "",
  skills: [
    emoji("▷ Building and scaling web applications and data pipelines, leveraging over a decade of industry experience."),
    emoji("▷ Specializes in thinking outside the box to find unique optimized solutions to difficult engineering problems."),
    emoji("▷ Passionate about blending technical expertise with a strategic approach to architectural design and problem-solving."),
    emoji("▷ It is not the actual work but the challenging creative process that goes behind it which inspires me to think out of the box to do something new, something satisfactory!"),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "golang",
      fontAwesomeClassname: "fa-brands fa-golang"
    },
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "kotlin",
      fontAwesomeClassname: "fab fa-k"
    },
    {
      skillName: "ruby",
      fontAwesomeClassname: "far fa-gem"
    },
    {
      skillName: "c/c++",
      fontAwesomeClassname: "fab fa-c"
    },
    {
      skillName: "javascript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "cloud",
      fontAwesomeClassname: "fa-solid fa-cloud"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "databases",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "you tell me?",
      fontAwesomeClassname: "fas fa-code"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Harvard University",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "September 2017 - April 2019",
      desc: "Participated in the research of XXX and published 3 papers.",
      descBullets: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      ]
    },
    {
      schoolName: "Stanford University",
      logo: require("./assets/images/stanfordLogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2013 - April 2017",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "💻 Software Development",
      progressPercentage: "90%"
    },
    {
      Stack: "🏗️ System Architecture & Design",
      progressPercentage: "90%"
    },
    {
      Stack: "💡 Creative Problem Solving",
      progressPercentage: "80%"
    },
    {
      Stack: "☁️ Cloud Infrastructure",
      progressPercentage: "85%"
    },
    {
      Stack: "🔗 Microservices",
      progressPercentage: "80%"
    },
    {
      Stack: "🛠️ DevOps",
      progressPercentage: "75%"
    },
    {
      Stack: "📊 Data Eng (AI/ML)",
      progressPercentage: "80%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Principal Engineer",
      company: "Atlassian",
      companylogo: require("./assets/images/atlassianLogo.webp"),
      date: "Sep 2022 – Present",
      desc: "At Atlassian, I am working with Enterprise Trust team to help build a next-generation platform that is envisaged to be the backbone of all enterprise data portability & data residency usecases.",
    },
    {
      role: "Software Architect",
      company: "Uber",
      companylogo: require("./assets/images/uberLogo.png"),
      date: "May 2019 – Aug 2022",
      desc: "As a key player at Uber, I led the building, scaling, and architecture of our customer platform, ensuring proactive and responsive solutions to defects, significantly enhancing customer, employee, and partner experience for increased satisfaction and operational excellence.",
    },
    {
      role: "SDE III",
      company: "Synaptic",
      companylogo: require("./assets/images/synapticLogo.jpeg"),
      date: "Jun 2018 – May 2019",
      desc: "At Synaptic, I was part of the Core Platform Development team, contributing to an alternative data platform designed to provide financial firms and investors with actionable insights from extensive data sets."
    },
    {
      role: "SDE II",
      company: "Shuttl",
      companylogo: require("./assets/images/shuttlLogo.png"),
      date: "May 2017 – June 2018",
      desc: "At Shuttl, I worked in Innovation Team (R&D). Being in a product based startup, I got to work on the bleeding edge of the Shuttl spectrum  which required developing and designing each and every bit including hardware, front end, back end, design, databases, project management."
    },
    {
      role: "SDE I",
      company: "Adobe",
      companylogo: require("./assets/images/adobeLogo.png"),
      date: "Jul 2014 – May 2017",
      desc: "I worked in Adobe Experience Manager team of Marketing Cloud as a Software Developer. My work involved developing new features and enhancing existing features in AEM (full-stack) which were a part of product roadmap and requirements of partners and vendors."
    },
    {
      role: "SDE Intern",
      company: "Webyog",
      companylogo: require("./assets/images/webyogLogo.jpg"),
      date: "Jan 2014 – Jul 2014",
      desc: "At Webyog, I transformed manual testing processes, significantly increased efficiency with Selenium and Java, and earned a full-time role from an internship, using Python, JavaScript, and HTML5/CSS3."
    },
    {
      role: "Microsoft Student Partner",
      company: "Microsoft",
      companylogo: require("./assets/images/microsoftLogo.jpg"),
      date: "2012 – 2014",
      desc: "As a Microsoft Student Partner, I was at the forefront of technology, passionately learning, sharing, and leading. Engaged in app development, training, and managing social media, utilizing skills in C++, mobile applications, Visual C#, and Windows programming, and actively contributing to the Microsoft Imagine Cup!"
    },
    {
      role: "Summer Intern",
      company: "D R D O",
      companylogo: require("./assets/images/drdoLogo.png"),
      date: "June 2012 – Jul 2012",
      desc: "At DRDO, within the ISSA Department and guided by Sir R.M. Singh, I spearheaded a project on 'Advanced Task Scheduling Optimization'. My role involved an in-depth study and analysis of sophisticated task scheduling algorithms, including SJF, FCFS along with more complex algorithms like Priority Scheduling and Multilevel Queue Scheduling."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  projects: [
    {
      image: require("./assets/images/uber_config.png"),
      projectName: "uConfigurator",
      projectDesc: "The core configurator platform service for the CO stack.",
      footerLink: [
        {
          name: "Read more",
          url: "https://www.uber.com/en-IN/blog/how-we-unified-configuration-distribution-across-systems-at-uber/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/uber_zorro.png"),
      projectName: "Zorro",
      projectDesc: "Automated PII masking data framework to protect customers' data and be GDPR compliant in our tickets.",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://nextu.se/"
        }
      ]
    },
    {
      image: require("./assets/images/shuttl_bus.png"),
      projectName: "Shuttl S.A.F.E.",
      projectDesc: "Auto onboarding authentication via face detection and recognition, ShuttlTV, Always on Alcohol detector with ignition cut off and all of this powered by a solo Raspberry Pi 3.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://web.archive.org/web/20230926113433/https://ficci.in/public/storage/events/23514/ISP/FICCI-Road-Safety-Awards-2017-Citation.pdf"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle: "A showcase of my accomplishments, certifications, awards, and some remarkable projects I've been a part of.",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        { name: "Certification", url: "#" },
        { name: "Award Letter", url: "#" },
        { name: "Google Code-in Blog", url: "#" }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        { name: "View Google Assistant Action", url: "#" }
      ]
    },
    {
      title: "PWA Web App Developer",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        { name: "Certification", url: "#" },
        { name: "Final Project", url: "#" }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle: "Driven by a passion for developing innovative solutions, I enjoy sharing my knowledge and experiences through writing and teaching.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "Tech Talks",
  subtitle: "Sharing my insights and experiences with a touch of humor, and yes, collecting those speaker swags along the way!",

  talks: [
    {
      title: "The Redis Paradox: Beyond the Cache Horizon!",
      subtitle: "Delhi FOSS 2.0, 2024",
      slides_url: "https://docs.google.com/presentation/d/1_c43HN79Gj12F-JmPMBLXxkGoEb1YcEe2i-r0K6s5lc/edit?usp=sharing",
      event_url: "https://web.archive.org/web/20240313104659/https://indiafoss.net/Delhi/2024"
    },
    {
      title: "Code the Future | Your Journey into Web Dev & SWE",
      subtitle: "TechXcelerate 2.0, 2024",
      slides_url: "https://docs.google.com/presentation/d/1Jn_43xywv4D6MibfeMyyNgsOk9xl-h-QTuxelE8X49g/edit?usp=sharing",
      event_url: "https://twitter.com/namespacecomm/status/1748049085138141252"
    },
    {
      title: "The Coder's Guide to Job Security: Mastering the Art of Confusing Code!",
      subtitle: "FOSS United Delhi, 2023",
      slides_url: "https://github.com/jatinkrmalik/FOSS-United-Delhi-Dec-2023",
      event_url: "https://twitter.com/FOSSUnitedDelhi/status/1735347369158557952?ref_src=twsrc%5Etfw"
    },
    {
      title: "Elevate Your Code // Practical Insights for 10X Impact",
      subtitle: "FOSS Conf Delhi, 2023",
      slides_url: "https://github.com/jatinkrmalik/FOSSConf-23-Keynote",
      event_url: "https://fossconf-2023.sessionize.com"
    },
    {
      title: "Data Portability in Enterprise Cloud",
      subtitle: "GDG Devfest Delhi, 2023",
      slides_url: "https://docs.google.com/presentation/d/1MxzBqfPfVoMJC8F3pfuFt5AIFqfLgYMxKoYKj2N-Knw/edit#slide=id.g286eb3297dd_0_6264",
      event_url: "https://twitter.com/gdg_nd/status/1705817660070719780"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "Some of my favorite podcasts",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://open.spotify.com/embed/show/20Gf4IAauFrfj7RBkjcWxh?utm_source=generator",
    "https://open.spotify.com/embed/show/20Gf4IAauFrfj7RBkjcWxh?utm_source=generator&theme=0"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me"),
  subtitle: "Discuss a project or just want to say hi?",
  email_address: "jatinkrmalik@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "jatinkrmalik", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
